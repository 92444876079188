/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Legacy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetUsersResponseItemUser
 */
export interface GetUsersResponseItemUser {
    /**
     * 
     * @type {boolean}
     * @memberof GetUsersResponseItemUser
     */
    calendarallowed: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetUsersResponseItemUser
     */
    cid: number;
    /**
     * 
     * @type {number}
     * @memberof GetUsersResponseItemUser
     */
    icon: number;
    /**
     * 
     * @type {string}
     * @memberof GetUsersResponseItemUser
     */
    initials: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetUsersResponseItemUser
     */
    isteam: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetUsersResponseItemUser
     */
    nachname: string;
    /**
     * 
     * @type {string}
     * @memberof GetUsersResponseItemUser
     */
    officemobile?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUsersResponseItemUser
     */
    officephone?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetUsersResponseItemUser
     */
    todelegate: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetUsersResponseItemUser
     */
    uid: string;
    /**
     * 
     * @type {string}
     * @memberof GetUsersResponseItemUser
     */
    vorname: string;
}

export function GetUsersResponseItemUserFromJSON(json: any): GetUsersResponseItemUser {
    return GetUsersResponseItemUserFromJSONTyped(json, false);
}

export function GetUsersResponseItemUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetUsersResponseItemUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'calendarallowed': json['calendarallowed'],
        'cid': json['cid'],
        'icon': json['icon'],
        'initials': json['initials'],
        'isteam': json['isteam'],
        'nachname': json['nachname'],
        'officemobile': !exists(json, 'officemobile') ? undefined : json['officemobile'],
        'officephone': !exists(json, 'officephone') ? undefined : json['officephone'],
        'todelegate': json['todelegate'],
        'uid': json['uid'],
        'vorname': json['vorname'],
    };
}

export function GetUsersResponseItemUserToJSON(value?: GetUsersResponseItemUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'calendarallowed': value.calendarallowed,
        'cid': value.cid,
        'icon': value.icon,
        'initials': value.initials,
        'isteam': value.isteam,
        'nachname': value.nachname,
        'officemobile': value.officemobile,
        'officephone': value.officephone,
        'todelegate': value.todelegate,
        'uid': value.uid,
        'vorname': value.vorname,
    };
}


